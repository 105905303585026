import React, { useState } from "react";
import "../styles/projectPage.css";
import MonitorScene from "../components/MonitorScene";

function ProjectsPage() {
  const projects = [
    {
      id: 1,
      name: "Al Naamani Logistics",
      description:
        "A website created using React for a local logistics company, featuring a responsive design and dynamic components.",
      image: "/assets/images/alnaamaniLogistics.jpg",
      technologies: ["React", "CSS", "JavaScript"],
      demo: "https://alnaamanilogistics.com/",
    },
    {
      id: 2,
      name: "Simon Game",
      description:
        "A dynamic version of the Simon Game built with jQuery, featuring responsive design for smooth gameplay.",
      image: "/assets/images/simonGamePic.jpg",
      technologies: ["jQuery", "JavaScript", "CSS"],
      github: "https://github.com/alnaamaniahmed/simonGame",
      demo: "https://alnaamaniahmed.github.io/simonGame/",
    },
    {
      id: 3,
      name: "Art By You",
      description:
        "Developed a responsive website with Bootstrap, integrated dynamic content using PHP and MySQL, and managed databases with phpMyAdmin.",
      image: "/assets/images/artByYouPic.jpg",
      technologies: ["PHP", "MySQL", "Bootstrap"],
      github: "https://github.com/alnaamaniahmed/artbyyou-",
    },
    {
      id: 4,
      name: "Personal Website",
      description:
        "A personal portfolio website showcasing projects and skills, developed with React.js and CSS for responsive design.",
      image: "/assets/images/myPersonalWebPic.jpg",
      technologies: ["React", "Three.js", "CSS"],
      demo: "https://ahmedalnaamani.dev/",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [animationClass, setAnimationClass] = useState("");
  const preloadImage = (src) => {
    const img = new Image();
    img.src = src;
  };

  const nextSlide = () => {
    if (isAnimating) return;
    setAnimationClass("fade-out");
    setIsAnimating(true);
    setTimeout(() => {
      const newIndex = (currentIndex + 1) % projects.length;
      preloadImage(projects[newIndex].image);
      setCurrentIndex(newIndex);
      setAnimationClass("fade-in");
      setTimeout(() => setIsAnimating(false), 600);
    }, 500);
  };

  const prevSlide = () => {
    if (isAnimating) return;
    setAnimationClass("fade-out");
    setIsAnimating(true);
    setTimeout(() => {
      const newIndex = (currentIndex - 1 + projects.length) % projects.length;
      preloadImage(projects[newIndex].image);
      setCurrentIndex(newIndex);
      setAnimationClass("fade-in");
      setTimeout(() => setIsAnimating(false), 600);
    }, 500);
  };

  return (
    <div className="page-transition">
      <div className="carouselContainer">
        <h1 className="projectsTitle">Recent Projects</h1>
        <p className="projectsSubtitle">Here's a glimpse of my latest work</p>
        <div className="carousel">
          <div className={`carouselCard ${animationClass}`}>
            <div className="monitorWrapper">
              <MonitorScene image={projects[currentIndex].image} />
            </div>
            <h2 className="projectName">{projects[currentIndex].name}</h2>
            <p className="projectDescription">
              {projects[currentIndex].description}
            </p>
            <div className="technologies">
              {projects[currentIndex].technologies.map((tech, index) => (
                <button key={index} className="techButton">
                  {tech}
                </button>
              ))}
            </div>
            <div className="projectButtons">
              {projects[currentIndex].github && (
                <button
                  className="githubButton"
                  onClick={() =>
                    window.open(projects[currentIndex].github, "_blank")
                  }
                >
                  Code
                </button>
              )}
              {projects[currentIndex].demo && (
                <button
                  className="demoButton"
                  onClick={() =>
                    window.open(projects[currentIndex].demo, "_blank")
                  }
                >
                  Demo
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="arrow left" onClick={prevSlide}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              fill="white"
            >
              <path
                d="M15.5 19.5L7.5 12L15.5 4.5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="arrow right" onClick={nextSlide}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              fill="white"
            >
              <path
                d="M8.5 4.5L16.5 12L8.5 19.5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsPage;
