import React from "react";
import "../styles/splash.css";

function SplashPage() {
  return (
    <div className="splash">
      <div className="splash-overlay">
        <div className="splash-logo-wrapper">
          <span className="splash-logo-symbol">&lt;</span>
          Great things take time... almost ready!
          <span className="splash-logo-symbol">/&gt;</span>
        </div>
        <div className="splash-loading-bar">
          <div className="splash-progress"></div>
        </div>
      </div>
    </div>
  );
}

export default SplashPage;
