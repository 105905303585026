import React, { useState, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ProjectsPage from "./pages/ProjectsPage";
import ExperiencePage from "./pages/ExperiencePage";
import ContactPage from "./pages/ContactPage";
import Footer from "./components/Footer";
import SplashPage from "./pages/SplashPage";
import "./styles/App.css";

const preloadImage = (src) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = resolve;
    img.onerror = reject;
    img.src = src;
  });

const preloadModel = (path) =>
  new Promise((resolve, reject) => {
    fetch(path)
      .then((res) => {
        if (res.ok) resolve();
        else reject(`Failed to load model: ${path}`);
      })
      .catch(reject);
  });

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const preloadAssets = async () => {
      try {
        const images = [
          "/assets/images/alnaamaniLogistics.jpg",
          "/assets/images/artByYouPic.jpg",
          "/assets/images/myPersonalWebPic.jpg",
          "/assets/images/simonGamePic.jpg",
        ];

        const models = [
          "/assets/models/mePosing.glb",
          "/assets/models/meSquatting.glb",
          "/assets/models/meWaving.glb",
        ];

        await Promise.all([
          ...images.map(preloadImage),
          ...models.map(preloadModel),
        ]);
        setLoading(false);
      } catch (error) {
        console.error("Error preloading assets:", error);
      }
    };

    preloadAssets();
  }, []);

  return (
    <Router>
      <Suspense>
        <div className="main-cont">
          {loading ? (
            <SplashPage />
          ) : (
            <>
              <NavBar />
              <Routes>
                <Route path="/" element={<HomePage />} exact />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/projects" element={<ProjectsPage />} />
                <Route path="/experiences" element={<ExperiencePage />} />
                <Route path="/contact" element={<ContactPage />} />
              </Routes>
              <Footer />
            </>
          )}
        </div>
      </Suspense>
    </Router>
  );
}

export default App;
