import React, { useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { AnimationMixer, Clock } from "three";
import "../styles/posingAvatar.css";

const PosingAvatar = () => {
  const { scene, animations } = useGLTF("/assets/models/mePosing.glb");
  useEffect(() => {
    if (animations && animations.length > 0) {
      const mixer = new AnimationMixer(scene);
      const action = mixer.clipAction(animations[0]);
      action.play();

      const clock = new Clock();
      const animate = () => {
        const delta = clock.getDelta();
        mixer.update(delta);
        requestAnimationFrame(animate);
      };
      animate();

      return () => mixer.stopAllAction();
    }
  }, [scene, animations]);
  return (
    <div className="PosingCanvas-wrapper">
      <div className="touch-layer"></div>
      <Canvas>
        <ambientLight intensity={0.5} />
        <directionalLight position={[5, 5, 5]} />
        <primitive object={scene} scale={1} position={[0, 2.3, 0]} />
        <OrbitControls
          enableRotate={false}
          enableZoom={false}
          maxPolarAngle={Math.PI / 9}
          minPolarAngle={Math.PI / 2.9}
        />
      </Canvas>
    </div>
  );
};

export default PosingAvatar;
