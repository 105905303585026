import React, { useEffect } from "react";
import "../styles/ExperiencePage.css";

const experiences = [
  {
    id: 1,
    role: "Freelance Web Developer",
    company: "Al Naamani Logistics",
    location: "Muscat, Oman",
    duration: "Aug 2024 - Sep 2024",
    description: [
      "Built a responsive website using JavaScript, HTML/CSS, and Node.js, enhancing online visibility.",
      "Integrated a third-party contact form using Formspree API for simplified user submissions.",
      "Contributed to a 5% increase in the company’s profit through website development.",
    ],
    logo: "/assets/icons/alnaamaniLogisticsLogo.png",
  },
  {
    id: 2,
    role: "Information Security Officer Intern",
    company: "AhliBank",
    location: "Muscat, Oman",
    duration: "Jul 2024 - Sep 2024",
    description: [
      "Reviewed over 20 security reports to identify unauthorized access and ensure compliance with ISO 27001.",
      "Independently developed a VLOOKUP formula to enhance data analysis efficiency.",
      "Conducted a phishing email test using GoPhish to raise employee awareness about cybersecurity risks.",
    ],
    logo: "/assets/icons/ahliLogo.jpg",
  },
  {
    id: 3,
    role: "Cast Member",
    company: "Cineplex",
    location: "Halifax, NS",
    duration: "May 2023 - Aug 2023",
    description: [
      "Served and helped more than 100 customers on shifts to ensure that their experience was positive.",
      "Worked with a team of 10 in a fast-paced environment, ensuring smooth operations when under pressure.",
      "Processed cash transactions with high accuracy to support financial operation integrity.",
    ],
    logo: "/assets/icons/cineplexLogo.jpg",
  },
];

function ExperiencePage() {
  useEffect(() => {
    const handleScroll = () => {
      const cards = document.querySelectorAll(".card");
      cards.forEach((card) => {
        const rect = card.getBoundingClientRect();
        if (rect.top < window.innerHeight - 100) {
          card.classList.add("visible");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="experienceContainer">
      <h1 className="experienceTitle">Experiences</h1>
      <p className="experienceSubtitle">
        My work experience at various companies
      </p>
      <div className="timeline">
        <div className="timelineLine"></div>
        {experiences.map((exp, index) => (
          <div className="timelineItem" key={exp.id}>
            {index % 2 === 0 ? (
              <>
                <div className="card left">
                  <img
                    src={exp.logo}
                    alt={`${exp.company} Logo`}
                    className="companyLogo"
                  />
                  <h2 className="role">{exp.role}</h2>
                  <h3 className="company">{exp.company}</h3>
                  <p className="location">{exp.location}</p>
                  <p className="duration">{exp.duration}</p>
                  <ul className="descriptionList">
                    {exp.description.map((desc, idx) => (
                      <li key={idx}>{desc}</li>
                    ))}
                  </ul>
                </div>
                <div className="timelineMarker">
                  <span className="markerDate">{exp.duration}</span>
                </div>
              </>
            ) : (
              <>
                <div className="timelineMarker">
                  <span className="markerDate">{exp.duration}</span>
                </div>
                <div className="card right">
                  <img
                    src={exp.logo}
                    alt={`${exp.company} Logo`}
                    className="companyLogo"
                  />
                  <h2 className="role">{exp.role}</h2>
                  <h3 className="company">{exp.company}</h3>
                  <p className="location">{exp.location}</p>
                  <p className="duration">{exp.duration}</p>
                  <ul className="descriptionList">
                    {exp.description.map((desc, idx) => (
                      <li key={idx}>{desc}</li>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ExperiencePage;
