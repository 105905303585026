import React from "react";
import "../styles/homePage.css";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import WavingAvatar from "../components/WavingAvatar";

function HomePage() {
  const [text] = useTypewriter({
    words: ["Full Stack Web Developer", "Software Engineer"],
    loop: {},
    typeSpeed: 120,
    deleteSpeed: 80,
  });
  return (
    <div className="page-transition">
      <div className="home-cont">
        <div className="me">
          <WavingAvatar />
        </div>
        <div className="text-cont">
          <div className="text-1">Hey There!</div>
          <div className="text-2">
            I'M <span id="name">Ahmed Al-Naamani</span>
          </div>
          <div className="text-3">
            {text}
            <span id="cursor">
              <Cursor />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default HomePage;
