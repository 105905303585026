import React from "react";
import "../styles/footer.css";

function Footer(props) {
  const year = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <h2>Ahmed Al-Naamani</h2>
          <p>Code. Create. Inspire.</p>
        </div>
        <div className="footer-links">
          <a
            href="https://www.github.com/alnaamaniahmed"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              className="icon"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Social Icons" clip-path="url(#clip0_82_481)">
                <path
                  id="Vector"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.0083 0C4.47396 0 0 4.50694 0 10.0826C0 14.5396 2.86662 18.3123 6.84338 19.6476C7.34058 19.748 7.5227 19.4306 7.5227 19.1637C7.5227 18.93 7.50631 18.1288 7.50631 17.2939C4.72225 17.895 4.14249 16.092 4.14249 16.092C3.69508 14.9235 3.03215 14.6232 3.03215 14.6232C2.12092 14.0055 3.09852 14.0055 3.09852 14.0055C4.1093 14.0723 4.63969 15.0405 4.63969 15.0405C5.53432 16.5761 6.97592 16.1422 7.55588 15.8751C7.63865 15.224 7.90394 14.7733 8.18563 14.523C5.96514 14.2893 3.62891 13.4213 3.62891 9.54836C3.62891 8.44662 4.02634 7.54523 4.65608 6.8442C4.55672 6.59386 4.20866 5.5587 4.75564 4.17322C4.75564 4.17322 5.60069 3.90608 7.5061 5.20818C8.32188 4.98747 9.16317 4.8752 10.0083 4.87426C10.8533 4.87426 11.7148 4.99123 12.5102 5.20818C14.4159 3.90608 15.2609 4.17322 15.2609 4.17322C15.8079 5.5587 15.4596 6.59386 15.3603 6.8442C16.0066 7.54523 16.3876 8.44662 16.3876 9.54836C16.3876 13.4213 14.0514 14.2725 11.8143 14.523C12.179 14.8401 12.4936 15.441 12.4936 16.3926C12.4936 17.7446 12.4773 18.8298 12.4773 19.1635C12.4773 19.4306 12.6596 19.748 13.1566 19.6478C17.1333 18.3121 20 14.5396 20 10.0826C20.0163 4.50694 15.526 0 10.0083 0Z"
                />
              </g>
              <defs>
                <clipPath id="clip0_82_481">
                  <rect width="20" height="20" />
                </clipPath>
              </defs>
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/in/ahmedalnaamani"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              className="icon"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Social Icons" clip-path="url(#clip0_82_483)">
                <path
                  id="Vector"
                  d="M16.6676 0H1.32891C0.594141 0 0 0.580078 0 1.29727V16.6992C0 17.4164 0.594141 18 1.32891 18H16.6676C17.4023 18 18 17.4164 18 16.7027V1.29727C18 0.580078 17.4023 0 16.6676 0ZM5.34023 15.3387H2.66836V6.74648H5.34023V15.3387ZM4.0043 5.57578C3.14648 5.57578 2.45391 4.8832 2.45391 4.02891C2.45391 3.17461 3.14648 2.48203 4.0043 2.48203C4.85859 2.48203 5.55117 3.17461 5.55117 4.02891C5.55117 4.87969 4.85859 5.57578 4.0043 5.57578ZM15.3387 15.3387H12.6703V11.1621C12.6703 10.1672 12.6527 8.88398 11.2816 8.88398C9.89297 8.88398 9.68203 9.97031 9.68203 11.0918V15.3387H7.01719V6.74648H9.57656V7.9207H9.61172C9.9668 7.2457 10.8387 6.53203 12.1359 6.53203C14.8395 6.53203 15.3387 8.31094 15.3387 10.6242V15.3387Z"
                />
              </g>
              <defs>
                <clipPath id="clip0_82_483">
                  <rect width="18" height="18" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
        <div className="footer-copy">
          <p>© {year} Ahmed Al-Naamani. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
