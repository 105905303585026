import React, { useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, useTexture } from "@react-three/drei";
import "../styles/monitorScene.css";

const Monitor = ({ image }) => {
  const texture = useTexture(image);

  return (
    <mesh>
      <mesh position={[0, 1, 0]}>
        <boxGeometry args={[3.1, 2.1, 0.1]} />
        <meshStandardMaterial color="#333" />
      </mesh>
      <mesh position={[0, 1, 0.065]}>
        <planeGeometry args={[2.9, 1.9]} />
        <meshBasicMaterial map={texture} />
      </mesh>
      <mesh position={[0, 0.4, -0.15]}>
        <cylinderGeometry args={[0.1, 0.1, 1.2, 32]} />
        <meshStandardMaterial color="#333" />
      </mesh>
      <mesh position={[0, -0.2, -0.15]}>
        <boxGeometry args={[1.5, 0.2, 0.8]} />
        <meshStandardMaterial color="#333" />
      </mesh>
      <mesh position={[0, -0.19, -0.15]}>
        <boxGeometry args={[1.6, 0.05, 0.85]} />
        <meshStandardMaterial color="#892cdc" />
      </mesh>
    </mesh>
  );
};

const MonitorScene = ({ image }) => {
  const [scale, setScale] = useState([2, 2, 2]);
  const [position, setPosition] = useState([0, -1.8, 0]);
  useEffect(() => {
    const updateScaleAndPosition = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 350) {
        setScale([1.25, 1.25, 1.25]);
        setPosition([0, -1.2, 0]);
      } else if (screenWidth <= 576) {
        setScale([1.3, 1.3, 1.3]);
        setPosition([0, -1.2, 0]);
      } else if (screenWidth <= 768) {
        setScale([1.5, 1.5, 1.5]);
        setPosition([0, -1.4, 0]);
      } else {
        setScale([2, 2, 2]);
        setPosition([0, -1.8, 0]);
      }
    };

    updateScaleAndPosition();
    window.addEventListener("resize", updateScaleAndPosition);

    return () => {
      window.removeEventListener("resize", updateScaleAndPosition);
    };
  }, []);

  return (
    <div className="monitorCanvas-wrapper">
      <Canvas style={{ width: "100%", height: "100%" }}>
        <ambientLight intensity={0.5} />
        <directionalLight position={[5, 5, 5]} />
        <group scale={scale} position={position}>
          <Monitor image={image} />
        </group>
        <OrbitControls
          enableRotate={false}
          enableZoom={false}
          maxPolarAngle={Math.PI / 9}
          minPolarAngle={Math.PI / 2}
        />
      </Canvas>
    </div>
  );
};

export default MonitorScene;
